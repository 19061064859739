<template lang="">
  <div>
    <v-container>
      <v-card :elevation="1" class="v-sheet theme--light br-0">
        <div class="row mt-0 mb-4">
          <div
            class="col-12 col-md-12 m-0 p-0"
            style="margin-top: -5px !important;"
          >
            <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
          </div>
        </div>
        <v-card-title>
          <v-row class="col-md-12">
            <v-col cols="12" sm="12" md="12">
              <p class="text-h5 font-weight-black mb-0">
                Reporte Matriz Cooperación Norte Sur
              </p>
              <!--<v-subheader class="pl-0 font-weight-black text-subtitle-1 text--blue-grey text--lighten-4">
                                
                            </v-subheader>-->
            </v-col>
          </v-row>

          <v-row class="col-md-12 m-0 p-0">
            <v-divider></v-divider>
          </v-row>
        </v-card-title>
        <v-card-text class="pr-0 pl-0">
          <v-container class="p-8">
            <v-form
              ref="formDatosFinancieros"
              v-on:submit.prevent="buscarProyectos"
              v-model="validFormBusqueda"
            >
              <v-row>
                <!--inicio sector-->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="datosBusqueda.sectoresId"
                    :items="sectoresBeneficiados"
                    class=""
                    :clearable="false"
                    dense
                    filled
                    :loading="ddSectoresLoading"
                    :disabled="btnBusquedaLoading"
                    label="Sector Beneficiado"
                    item-text="sector"
                    item-value="id"
                    :rules="[
                      datosBusqueda.tipoConsulta == 1
                        ? selectRequired('sector')
                        : true
                    ]"
                  ></v-autocomplete>
                </v-col>
                <!--fin:: sector-->

                <!--inicio institucion-->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="datosBusqueda.entidadId"
                    :items="instituciones"
                    class=""
                    :clearable="false"
                    dense
                    filled
                    :loading="ddInstitucionesLoading"
                    :disabled="btnBusquedaLoading"
                    label="Institución"
                    :item-text="
                      item =>
                        item.sigla
                          ? `${item.nombreInstitucion} (${item.sigla})`
                          : item.nombreInstitucion
                    "
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <!--fin:: institucion-->
              </v-row>
              <v-row>
                <!--inicio:: fuente cooperante -->

                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    v-model="datosBusqueda.tiposFuenteCooperanteId"
                    :items="tiposFuenteCooperante"
                    :loading="false"
                    dense
                    filled
                    class="required"
                    label="Tipo fuente cooperante"
                    item-text="nombreTipoFuente"
                    item-value="id"
                    :no-data-text="
                      tiposFuenteCooperante != null
                        ? 'Selecciona un tipo de fuente cooperante'
                        : 'No se han encontrado tipos de fuente cooperante'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="datosBusqueda.tiposFuenteCooperanteId == 1"
                >
                  <v-select
                    v-model="datosBusqueda.fuentesBilateralesId"
                    :items="fuentesBilaterales"
                    :loading="false"
                    :required="datosBusqueda.tiposFuenteCooperanteId == 1"
                    dense
                    :class="
                      datosBusqueda.tiposFuenteCooperanteId == 1
                        ? 'required'
                        : ''
                    "
                    filled
                    label="País Fuente Bilateral"
                    item-text="nombre"
                    item-value="id"
                    :no-data-text="
                      fuentesBilaterales != null
                        ? 'Selecciona una institución ejecutora'
                        : 'No se han encontrado unidades ejecutoras'
                    "
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="datosBusqueda.tiposFuenteCooperanteId == 2"
                >
                  <v-select
                    v-model="datosBusqueda.fuentesMultilateralesId"
                    :items="fuentesMultilateral"
                    :loading="false"
                    dense
                    filled
                    label="Seleccione fuente multilateral"
                    item-text="nombreFuenteMultilateral"
                    item-value="id"
                    :no-data-text="
                      fuentesMultilateral != null
                        ? 'Seleccione una fuente multilateral'
                        : 'No se han encontrado fuentes multilaterales'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <!--fin:: fuente cooperante-->
              </v-row>
              <v-row>
                <!--inicio estado -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    v-model="datosBusqueda.estadoProyectoId"
                    :items="estadosProyecto"
                    :loading="ddEstadosLoading"
                    dense
                    class=""
                    filled
                    label="Estado"
                    item-text="estado"
                    item-value="id"
                    :no-data-text="
                      estadosProyecto != null
                        ? 'Seleccione un estado'
                        : 'No se han encontrado estados'
                    "
                  ></v-select>
                </v-col>
                <!--fin:: estado -->

                <!--inicio:: tipo de cooperacion -->
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    v-model="datosBusqueda.tipoCooperacionId"
                    :items="tiposCooperacion"
                    :loading="tiposCooperacionLoading"
                    dense
                    filled
                    class="required"
                    label="Tipo de cooperación"
                    item-text="nombreTipoCooperacion"
                    item-value="id"
                    :no-data-text="
                      tiposCooperacion != null
                        ? 'Seleccione un tipo de cooperación'
                        : 'No se han encontrado tipos de cooperación'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>
                <!--fin:: tipo de cooperacion-->
              </v-row>
              <v-row>
                <!--inicio:: fehcas-->
                <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                  <v-menu
                    ref="menuFechaInicio"
                    v-model="menuFechaInicio"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        :clearable="true"
                        @click:clear="fechaInicioChangedClear"
                        filled
                        color="blue-grey lighten-2"
                        v-model="computedFechaInicio"
                        label="Fecha de inicio"
                        hint="DD/MM/AAAA"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        v-mask="'##/##/####'"
                        maxlength="10"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateFechaInicio"
                      no-title
                      @input="menuFechaInicio = false"
                      locale="es"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                  <v-menu
                    ref="menuFechaFinalizacion"
                    v-model="menuFechaFinalizacion"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        :clearable="true"
                        @click:clear="fechaFinalizacionChangedClear"
                        color="blue-grey lighten-2"
                        v-model="computedFechaFinalizacion"
                        label="Fecha de finalización"
                        hint="DD/MM/AAAA"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        v-mask="'##/##/####'"
                        maxlength="10"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateFechaFinalizacion"
                      no-title
                      @input="menuFechaFinalizacion = false"
                      locale="es"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!--fin:: fechas -->
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    class="white--text float-right  mr-1 mb-2"
                    large
                    color="light-blue-502"
                    :loading="btnBusquedaLoading"
                    :disabled="btnBusquedaLoading"
                    type="submit"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar proyectos
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row>
              <v-col cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-btn
                  class="white--text float-right  mr-1 mb-2"
                  x-large
                  color="teal lighten-1"
                  :loading="false"
                  :disabled="btnBusquedaLoading || proyectos.length <= 0"
                  @click="generarArchivo(datosBusqueda.tipoConsulta, 'xls')"
                  type="submit"
                >
                  <v-icon left>mdi-file-excel-box</v-icon> Descargar xls
                </v-btn>

                <v-btn
                  class="white--text float-right  mr-1 mb-2"
                  x-large
                  color="red lighten-1"
                  :loading="false"
                  :disabled="btnBusquedaLoading || proyectos.length <= 0"
                  @click="generarArchivo(datosBusqueda.tipoConsulta, 'pdf')"
                  type="submit"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Descargar pdf
                </v-btn>

                <v-btn
                  class="white--text float-right  mr-1 mb-2"
                  x-large
                  color="light-blue-502"
                  :loading="false"
                  :disabled="btnBusquedaLoading || proyectos.length <= 0"
                  @click="generarArchivo(datosBusqueda.tipoConsulta, 'csv')"
                  type="submit"
                >
                  <v-icon left>mdi-file-delimited</v-icon> Descargar csv
                </v-btn>

                <!-- <v-btn
                    color="grey lighten-5"
                    elevation="0"
                    class="mb-2 float-right grey lighten-4 mr-2"
                    :loading="false"
                    x-large
                    :disabled="btnBusquedaLoading"
                    @click="limpiarFiltros()"
                  >
                    <v-icon>mdi-trash</v-icon>
                    Limpiar
                  </v-btn> -->
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  class="elevation-0"
                  :headers="headers"
                  :header-props="{
                    sortByText: 'Ordenar por'
                  }"
                  :items="proyectos"
                  @current-items="proyectosFiltrados"
                  :search="filtro"
                  :loading="tableLoading"
                  no-data-text="No se encontraron registros"
                  loading-text="Cargando..."
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}'
                  }"
                >
                  <template v-slot:top>
                    <!-- v-container, v-col and v-row are just for decoration purposes. -->
                    <v-container fluid>
                      <v-row>
                        <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                          <v-btn
                            class="ma-0 white--text d-none"
                            medium
                            color="light-blue darken-2"
                          >
                            <v-icon left>mdi-magnify</v-icon> Buscar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{ item.codigoSicoopera }}
                      </td>
                      <td>
                        {{ item.codigoSigeaci ? item.codigoSigeaci : "N/A" }}
                      </td>
                      <td>{{ item.nombreProyecto }}</td>
                      <td>{{ item.tipoCooperacion }}</td>
                      <td>{{ item.fuenteCooperante }}</td>
                      <!--<td>{{ item.fechaSolicitudOpinionTecnica | formatDate }}</td>-->
                      <td>
                        {{ item.nombreInstitucion }} <br />
                        {{
                          item.nombreUnidadEjecutora
                            ? item.nombreUnidadEjecutora
                            : ``
                        }}
                      </td>
                      <td>{{ item.fechaInicioPlazos }}</td>
                      <td>{{ item.fechaFinalizacionPlazos }}</td>

                      <td>
                        <v-chip
                          label
                          class="ma-2"
                          :color="
                            item.estadoProyectoId === 1
                              ? 'blue-grey lighten-4'
                              : item.estadoProyectoId === 2
                              ? 'blue lighten-4'
                              : item.estadoProyectoId === 3
                              ? 'deep-orange lighten-4'
                              : item.estadoProyectoId === 5
                              ? 'cyan lighten-4'
                              : item.estadoProyectoId === 6
                              ? 'teal lighten-4'
                              : item.estadoProyectoId === 7
                              ? 'deep-orange lighten-4'
                              : item.estadoProyectoId === 8
                              ? 'red lighten-4'
                              : 'blue-grey lighten-4'
                          "
                          :text-color="
                            item.estadoProyectoId === 1
                              ? 'blue-grey lighten-1'
                              : item.estadoProyectoId === 2
                              ? 'blue lighten-1'
                              : item.estadoProyectoId === 3
                              ? 'deep-orange lighten-1'
                              : item.estadoProyectoId === 5
                              ? 'cyan lighten-1'
                              : item.estadoProyectoId === 6
                              ? 'teal lighten-1'
                              : item.estadoProyectoId === 7
                              ? 'deep-orange lighten-1'
                              : item.estadoProyectoId === 8
                              ? 'red lighten-1'
                              : 'blue-grey lighten-1'
                          "
                          small
                        >
                          {{ item.estadoProyecto }}
                        </v-chip>
                      </td>
                      <td class="text-right">
                        {{
                          parseFloat(item.montoSuscrito).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ",
                              maximumFractionDigits: 2
                            }
                          )
                        }}
                      </td>
                      <td>
                        {{
                          parseFloat(item.montoEjecutado).toLocaleString(
                            "es-GT",
                            {
                              style: "currency",
                              currency: "GTQ",
                              maximumFractionDigits: 2
                            }
                          )
                        }}
                      </td>
                      <td>{{ parseFloat(item.avanceFisico).toFixed(2) }}%</td>
                      <td>
                        {{ parseFloat(item.avanceFinanciero).toFixed(2) }}%
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>
<script>
import moment from "moment";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import DialogLoader from "@/view/content/DialogLoader";
import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import validations from "@/core/untils/validations.js";
//import { REPORTE_DOCUEMNTOS_CNS } from "@/core/services/store/proyectoscns/proyectocns.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
export default {
  name: "ReporteGeneralCns",
  components: {
    DivisorColor,
    SnackAlert,
    DialogLoader
  },
  data() {
    return {
      validFormBusqueda: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      departamentosLoading: false,
      departamentos: [],
      ddEstadosLoading: false,
      btnBusquedaLoading: false,
      ddSectoresLoading: false,
      ddInstitucionesLoading: false,
      tiposFuenteCooperante: [],
      fuenteBilateralSeleccionada: {},
      fuentesBilaterales: [],
      fuenteMultilateralSeleccionada: {},
      fuentesMultilateral: [],
      sectoresBeneficiados: [],
      instituciones: [],
      datosBusqueda: {
        tipoConsulta: 0,
        tiposFuenteCooperanteId: 0,
        tipoCooperacionId: 0
      },
      tiposConsulta: [
        //{ id: 1, tipo: "Reporte Matriz" },
        { id: 1, tipo: "Por Sector" },
        { id: 2, tipo: "Por Territorio" },
        { id: 3, tipo: "Por Institución" },
        { id: 4, tipo: "Por Fuente Cooperante" },
        { id: 5, tipo: "Por Estado del Proyecto" }
      ],
      frameUrl: ``,
      proyectosFiltrados: [],
      tableLoading: false,
      proyectos: [],
      menuFechaInicio: false,
      menuFechaFinalizacion: false,
      dateFechaInicio: null,
      dateFechaFinalizacion: null,
      tiposCooperacion: [],
      tiposCooperacionLoading: false,
      filtro: "",
      ...validations
    };
  },

  methods: {
    //Sectores beneficiados
    async obtenerSectoresBeneficiados() {
      this.sectoresBeneficiados = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Sectores/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.sectoresBeneficiados = res.data;
            this.sectoresBeneficiados.unshift({ id: 0, sector: "Todos" });
            this.datosBusqueda.sectoresId = 0;
          }
        })
        .catch(() => {
          this.sectoresBeneficiados = [];
        });
    },

    //Obtener departamentos
    async obtenerDepartamentos() {
      this.departamentosLoading = true;
      this.departamentos = [];
      //this.departamentosActividad = [];
      await this.$store
        .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
        .then(res => {
          if (res.status === 200) {
            this.departamentos = res.data;
            this.departamentos.unshift({ id: 0, departamento: "Todos" });
            this.datosBusqueda.departamentosId = 0;
            //this.departamentosActividad = res.data;
          }
          this.departamentosLoading = false;
          // this.departamentosActividadLoading=false;
        })
        .catch(() => {
          this.departamentos = [];
          //this.departamentosActividad = [];
          this.departamentosLoading = false;
          //this.departamentosActividadLoading=false;
        });
    },

    async obtenerTiposFuenteCooperante() {
      this.tiposFuenteCooperante = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposFuenteCooperante/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposFuenteCooperante = res.data;
            this.tiposFuenteCooperante.splice(1, 1);
            this.tiposFuenteCooperante.unshift({
              id: 0,
              nombreTipoFuente: "Todas"
            });
          }
        })
        .catch(() => {
          this.tiposFuenteCooperante = [];
        });
    },

    async obtenerFuentesMultilateral() {
      this.fuentesMultilateral = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuenteMultiLateral/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilateral = res.data;
            this.fuentesMultilateral.unshift({
              id: 0,
              nombreFuenteMultilateral: "Todos"
            });
            this.datosBusqueda.fuentesMultilateralesId = 0;
          }
        })
        .catch(() => {
          this.fuentesMultilateral = [];
        });
    },

    async obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuentesBilaterales/all/1/0"
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
            this.fuentesBilaterales.unshift({ id: 0, nombre: "Todos" });
            this.datosBusqueda.fuentesBilateralesId = 0;
          }
        })
        .catch(() => {
          this.fuentesBilaterales = [];
        });
    },

    obtenerEstadosProyecto() {
      this.estadosProyecto = [
        { id: 0, estado: "Todos" },
        { id: 1, estado: "Registro" },
        { id: 2, estado: "Seguimiento" },
        { id: 3, estado: "Solicitud de Cierre/Liquidación" },
        { id: 4, estado: "Cierre/Liquidación" },
        { id: 5, estado: "Solicitud de Finalización" },
        { id: 6, estado: "Finalizado" },
        { id: 7, estado: "Solicitud de Suspendido" },
        { id: 8, estado: "Suspendido" }
      ];
    },

    //Tipos de cooperacion
    async obtenerTiposCooperacion() {
      this.tiposCooperacionLoading = true;
      this.tiposCooperacion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposCooperacion/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposCooperacion = res.data;
            this.tiposCooperacionLoading = false;
            this.tiposCooperacion.unshift({
              id: 0,
              nombreTipoCooperacion: "Todos"
            });
          }
        })
        .catch(() => {
          this.tiposCooperacion = [];
          this.tiposCooperacionLoading = false;
        });
    },

    //Obtener entidades o instituciones
    async obtenerEntidades() {
      this.instituciones = [];
      this.ddInstitucionesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.instituciones = res.data;

            this.instituciones.splice(0, 0, {
              nombreInstitucion: "TODAS",
              id: 0,
              sigla: "Todas las instituciones",
              estadosId: 1,
              restrictiva: "N",
              estado: "Activo"
            });

            //this.departamentos.unshift({ id: 0, departamento: "Todos" });
            this.datosBusqueda.entidadId = 0;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.instituciones = [];
          this.ddInstitucionesLoading = false;
        });
    },

    //Obtener los proyectos con los filtros establecidos
    async buscarProyectos() {
      this.btnBusquedaLoading = true;
      let idFC = 0;
      if (this.datosBusqueda.tiposFuenteCooperanteId == 1)
        idFC = this.datosBusqueda.fuentesBilateralesId;
      else if (this.datosBusqueda.tiposFuenteCooperanteId == 2)
        idFC = this.datosBusqueda.fuentesMultilateralesId;

      let fInicio = "0";
      let fFinalizacion = "0";

      if (this.dateFechaInicio != null) fInicio = this.dateFechaInicio;

      if (this.dateFechaFinalizacion != null)
        fFinalizacion = this.dateFechaFinalizacion;

      if (this.dateFechaInicio != null && this.dateFechaFinalizacion == null) {
        fFinalizacion = "9999-12-12";
      }

      if (this.dateFechaInicio == null && this.dateFechaFinalizacion != null) {
        fInicio = "0001-01-01";
      }

      let base = `ReporteGeneralDocumentosProyecto/General/Consulta/6/`;
      let endpoint =
        base +
        `${this.datosBusqueda.sectoresId}/${this.datosBusqueda.departamentosId}/${this.datosBusqueda.entidadId}/${this.datosBusqueda.tiposFuenteCooperanteId}/${idFC}/${this.datosBusqueda.estadoProyectoId}/${this.datosBusqueda.tipoCooperacionId}/${fInicio}/${fFinalizacion}`;

      this.proyectos = [];

      this.tableLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: endpoint })
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
          }
          this.tableLoading = false;
          this.btnBusquedaLoading = false;
        })
        .catch(() => {
          this.proyectos = [];
          this.tableLoading = false;
          this.btnBusquedaLoading = false;
        });
    },
    prepararParametros() {
      if (this.datosBusqueda.tipoConsulta == 1) {
        this.datosConsulta.sectoresId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 2) {
        this.datosBusqueda.departamentosId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 3) {
        this.datosBusqueda.entidadId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 4) {
        this.datosBusqueda.fuentesBilateralesId = 0;
        this.datosBusqueda.fuentesMultilateralesId = 0;
      } else if (this.datosBusqueda.tipoConsulta == 5) {
        this.datosBusqueda.estadoProyectoId = 0;
      }
    },
    //Tipo 1 pdf, 2 xls, 3 csv
    generarArchivo(tipoConsulta, tipoArchivo) {
      //console.log(this.dateFechaInicio + " " +  this.dateFechaFinalizacion);

      let idFC = 0;
      if (this.datosBusqueda.tiposFuenteCooperanteId == 1)
        idFC = this.datosBusqueda.fuentesBilateralesId;
      else if (this.datosBusqueda.tiposFuenteCooperanteId == 2)
        idFC = this.datosBusqueda.fuentesMultilateralesId;

      let fInicio = "0";
      let fFinalizacion = "0";

      if (this.dateFechaInicio != null) fInicio = this.dateFechaInicio;

      if (this.dateFechaFinalizacion != null)
        fFinalizacion = this.dateFechaFinalizacion;

      if (this.dateFechaInicio != null && this.dateFechaFinalizacion == null) {
        fFinalizacion = "9999-12-12";
      }

      if (this.dateFechaInicio == null && this.dateFechaFinalizacion != null) {
        fInicio = "0001-01-01";
      }

      let urlBase = `https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteMatrizCns/ReporteMatrizCns.${tipoArchivo}?endpoint=`;
      let urlParams = `${this.datosBusqueda.sectoresId}/${this.datosBusqueda.departamentosId}/${this.datosBusqueda.entidadId}/${this.datosBusqueda.tiposFuenteCooperanteId}/${idFC}/${this.datosBusqueda.estadoProyectoId}/${this.datosBusqueda.tipoCooperacionId}/${fInicio}/${fFinalizacion}&j_password=reportes&j_username=reporte`;
      // eslint-disable-next-line prettier/prettier
      this.frameUrl = urlBase+`ReporteGeneralDocumentosProyecto/General/Consulta/6/`+ urlParams;

      /*url por territorio/departamento
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsDepartamento/ReporteGeneralCnsDepartamento.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/3/0/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      /*url por institucion
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsInstitucion/ReporteGeneralCnsInstitucion.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/3/1/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      /*url por fuente cooperante
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsFuenteCooperante/ReporteGeneralCnsFuenteCooperante.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/4/0/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      /*url por estado
      https://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/ReporteGeneralCnsEstado/ReporteGeneralCnsEstado.html?endpoint=ReporteGeneralDocumentosProyecto/General/Consulta/5/0/0/0/0/0/0&j_password=reportes&j_username=reporte
      */

      window.open(this.frameUrl, "_blank");
    },

    formatDatePicker(date) {
      //console.log(date)
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    fechaInicioChangedClear() {
      this.dateFechaInicio = null;
    },

    fechaFinalizacionChangedClear() {
      this.dateFechaFinalizacion = null;
    }
  },

  created() {
    this.obtenerEntidades();
    this.obtenerDepartamentos();
    this.obtenerTiposFuenteCooperante();
    this.obtenerFuentesMultilateral();
    this.obtenerFuentesBilaterales();
    this.obtenerEstadosProyecto();
    this.obtenerSectoresBeneficiados();
    this.obtenerTiposCooperacion();
    this.datosBusqueda.estadoProyectoId = 0;
  },
  computed: {
    headers() {
      return [
        {
          text: "Código SICOOPERA",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Código SIGEACI",
          align: "start",
          sortable: false,
          value: "refSigeaci"
        },
        {
          text: "Nombre del proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Tipo de Cooperación",
          align: "start",
          sortable: true,
          value: "tipoCooperacion"
        },
        {
          text: "Fuente Cooperante",
          align: "start",
          sortable: true,
          value: "fuenteCooperante"
        },
        {
          text: "Institución Ejecutora",
          align: "start",
          sortable: true,
          value: "institucion"
        },
        {
          text: "Fecha inicio",
          align: "start",
          sortable: true,
          value: "fechaInicioPlazos"
        },
        {
          text: "Fecha finalización",
          align: "start",
          sortable: true,
          value: "fechaFinalizacionPlazos"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoProyecto",
          filter: this.filtroEstado
        },
        {
          text: "Monto Suscrito",
          align: "start",
          sortable: true,
          value: "montoSuscrito"
        },
        {
          text: "Monto Ejecutado",
          align: "start",
          sortable: true,
          value: "montoEjecutado"
        },
        {
          text: "% Avance Físico",
          align: "start",
          sortable: true,
          value: "avanceFisico"
        },
        {
          text: "% Avance Financiero",
          align: "start",
          sortable: true,
          value: "avanceFinanciero"
        }
      ];
    },
    computedFechaFinalizacion: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaFinalizacion);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },
    computedFechaInicio: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaInicio);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    }

    /* watch: {
      computedFechaFinalizacion: function(val) {
        this.
      }
    } */
  }
};
</script>
<style lang=""></style>
