<template lang="">
  <div>
    <ReportMatrizCns></ReportMatrizCns>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ReportMatrizCns from "@/view/pages/externo/reportes/ReporteMatrizCns.vue";
export default {
  components: {
    ReportMatrizCns
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/" },
      { title: "Consultas" },
      { title: "Proyectos CNS" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  },
}
</script>
<style lang="">
  
</style>